<template>
  <div class="mt-4 mb-8">
    <p class="text-subtitle-2">
      {{ label }}
    </p>
    <v-input
      :value="sanitizedHtml"
      :name="fullKey"
      :disabled="disabled"
      :rules="rules"
      :required="required"
    >
      <div v-if="htmlDescription" class="caption" v-html="htmlDescription" />
      <editor
        v-if="!disabled"
        api-key="ubnpbfigjlmsg5ef13hpbzvgx5fodkx8hlckejl1xi3cs6f3"
        :value="editorContent"
        :init="init"
        v-on="{ ...on, input }"
      />
      <v-card v-else outlined>
        <v-card-text v-html="sanitizedHtml" />
      </v-card>
    </v-input>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import sanitizeHtml from "sanitize-html";

export default {
  components: {
    Editor,
  },
  props: {
    value: { type: String, default: "" },
    options: { type: Object, required: true },
    schema: { type: Object, required: true },
    fullSchema: { type: Object, required: true },
    fullKey: { type: String, required: true },
    label: { type: String, default: "" },
    htmlDescription: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array, required: true },
    on: { type: Object, required: true },
  },
  data: () => ({
    init: {
      height: 400,
      plugins:
        "print paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
      toolbar:
        "code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image media link anchor codesample",
      contextmenu: "link image imagetools table",
      menubar: "file edit view insert format tools table help",
      language: "ca",
      convert_urls: false,
    },
    editorContent: null,
  }),
  beforeMount() {
    this.editorContent = this.value;
  },
  computed: {
    sanitizedHtml() {
      return (
        this.value &&
        sanitizeHtml(this.value, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
          allowedAttributes: false,
        })
      );
    },
  },
  methods: {
    input(value) {
      if (value === "<p></p>") {
        value = "";
      }
      // sanitizing should also be done when receiving this data in the backend
      this.on.input(
        sanitizeHtml(value, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
          allowedAttributes: false,
        })
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
